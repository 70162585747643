import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/dev-site/dev-site/src/templates/Page/index.ts";
import { FaRss as RssFeed } from 'react-icons/fa';
import { RiBracesFill as JsonFeed } from 'react-icons/ri';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Alert = makeShortcode("Alert");
const IpAddresses = makeShortcode("IpAddresses");
const LinkGroupContainer = makeShortcode("LinkGroupContainer");
const LinkGroup = makeShortcode("LinkGroup");
const LinkGroupCard = makeShortcode("LinkGroupCard");
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <Alert type="warning" mdxType="Alert">
      <p>{`In January 2024, we began using R2 presigned URLs for all database downloads.
Database downloads will no longer use the IP addresses specified below.`}</p>
      <p>{`For information about IP addresses used to serve database downloads see
`}
        <a {...{
          "href": "#database-download-ip-addresses",
          "parentName": "p"
        }}>{`the appropriate section below`}</a>
        {`.`}</p>
      <p><a {...{
          "href": "/geoip/release-notes/2024#presigned-urls-for-database-downloads",
          "parentName": "p"
        }}>{`Read our release note for more information.`}</a></p>
    </Alert>
    <p>{`This page lists the IP addresses that may be used for minFraud, GeoIP, and
GeoLite web service requests by our `}
      {`*`}
      {`.maxmind.com API hostnames. We strongly
encourage you to use DNS rather than hardcode our IPs. Not all IPs listed on
this page will be in use at a given time. The IPs returned in DNS could change
at any time. Note that the IPs below don’t apply to the `}
      <inlineCode {...{
        "parentName": "p"
      }}>{`www.maxmind.com`}</inlineCode>
      {`
hostname, which could use any IP and changes frequently.`}</p>
    <IpAddresses mdxType="IpAddresses" />
    <div {...{
      "id": "toc-monitoring-changes"
    }}><h2 {...{
        "id": "monitoring-changes",
        "parentName": "div"
      }}>{`Monitoring Changes`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`MaxMind provides RSS and JSON feeds, allowing you to monitor changes to our
minFraud, GeoIP, and GeoLite web service server IP addresses.`}</p>
      <LinkGroupContainer mdxType="LinkGroupContainer">
        <LinkGroup mdxType="LinkGroup">
          <LinkGroupCard description="Subscribe to our RSS and be notified when IP addresses change." heading="RSS Feed" icon={RssFeed} to="/maxmind-server-ip-addresses.xml" mdxType="LinkGroupCard" />
          <LinkGroupCard description="Integrate our JSON feed in your application for change automation." heading="JSON Feed" icon={JsonFeed} to="/maxmind-server-ip-addresses.json" mdxType="LinkGroupCard" />
        </LinkGroup>
      </LinkGroupContainer></div>
    <div {...{
      "id": "toc-database-download-ip-addresses"
    }}><h2 {...{
        "id": "database-download-ip-addresses",
        "parentName": "div"
      }}>{`Database download IP addresses`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`MaxMind uses Cloudflare to serve our GeoIP and GeoLite database downloads.`}</p>
      <p {...{
        "parentName": "div"
      }}><a {...{
          "href": "https://www.cloudflare.com/ips/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`You can view a list of Cloudflare IP ranges that may be used to serve MaxMind database downloads on their website.`}</a></p>
      <p {...{
        "parentName": "div"
      }}>{`Cloudflare also maintains
`}
        <a {...{
          "href": "https://developers.cloudflare.com/api/operations/cloudflare-i-ps-cloudflare-ip-details",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`an API that can be used to retrieve the list of Cloudflare IP ranges`}</a>
        {`.`}</p></div>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      